import { useContext, useEffect, useRef, useState } from 'react'
import type { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import {
  ButtonBack,
  ButtonNext,
  CarouselContext,
  CarouselProvider,
  Slide,
  Slider,
} from 'pure-react-carousel'

import type { ISectionInteractiveImageFields } from 'contentful-shared'
import { ContentfulImage } from 'bl-common/src/elements/ContentfulImage'
import { ChevronLeft } from 'bl-common/src/elements/Icons/ChevronLeft'
import { ChevronRight } from 'bl-common/src/elements/Icons/ChevronRight'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { RichTextRenderer } from 'bl-common/src/richText/RichTextRenderer'
import { Appear } from 'bl-common/src/units/Appear'
import { Section } from 'bl-common/src/units/Section/Section'
import { getImageUrl } from 'bl-common/src/units/SmartImage/getImageUrl'

import * as css from './styles'

type InteractiveImageSectionProps = {
  section: {
    fields: ISectionInteractiveImageFields
  }
}

type MobileCarouselProps = {
  items: ISectionInteractiveImageFields['items']
  image: ISectionInteractiveImageFields['mobileImage']
}

const options: Options = {
  renderNode: {
    [BLOCKS.HEADING_4]: (_, children) => (
      <Type as="h4" preset="text" weight="bold" bottom={{ xs: 0.5 }}>
        {children}
      </Type>
    ),
    [BLOCKS.PARAGRAPH]: (_, children) => (
      <Type as="p" preset="textSmall">
        {children}
      </Type>
    ),
  },
}

export const InteractiveImageSection = ({
  section: {
    fields: { image, mobileImage, items, config },
  },
}: InteractiveImageSectionProps) => {
  const mobileImageToUse = mobileImage || image

  const { width, height } = image?.fields?.file.details.image || {}

  if (!width || !height) {
    return null
  }

  return (
    <Section config={config} noHorizontalPadding>
      <Appear observer>
        <Appear>
          <css.DesktopImageWrap style={{ aspectRatio: `${width}/${height}` }}>
            <ContentfulImage image={image} />
            {items.map(item => (
              <css.InteractiveItem
                key={item.sys.id}
                position={item.fields.position}
                style={{
                  top: `${item.fields.top}%`,
                  left: `${item.fields.left}%`,
                }}
                tabIndex={0}
                aria-describedby={`tooltip-${image.sys.id}-${item.sys.id}`}
              >
                <css.InteractiveTooltip
                  id={`tooltip-${image.sys.id}-${item.sys.id}`}
                  position={item.fields.position}
                  role="tooltip"
                >
                  {item.fields.image ? (
                    <css.Image
                      image={getImageUrl(item.fields.image, { width: 400 })}
                      imageRatio={45 / 80}
                    />
                  ) : null}
                  <css.InteractiveTooltipContent>
                    <RichTextRenderer
                      document={item.fields.textContent}
                      customOptions={options}
                    />
                  </css.InteractiveTooltipContent>
                </css.InteractiveTooltip>
              </css.InteractiveItem>
            ))}
          </css.DesktopImageWrap>
          <css.MobileContainer>
            <CarouselProvider
              infinite
              naturalSlideWidth={300}
              naturalSlideHeight={200}
              totalSlides={items.length}
              dragEnabled={false}
              lockOnWindowScroll
              isIntrinsicHeight
              className="IICarousel"
            >
              <MobileCarousel items={items} image={mobileImageToUse} />
            </CarouselProvider>
          </css.MobileContainer>
        </Appear>
      </Appear>
    </Section>
  )
}

const MobileCarousel = ({ items, image }: MobileCarouselProps) => {
  const mobileImageRef = useRef<HTMLImageElement>(null)
  const carouselContext = useContext(CarouselContext)
  const [currentSlide, setCurrentSlide] = useState(
    carouselContext?.state?.currentSlide || 0
  )
  const { width, height } = image?.fields?.file.details.image || {}

  useEffect(() => {
    const onChange = () => setCurrentSlide(carouselContext.state.currentSlide)
    carouselContext.subscribe(onChange)

    return () => carouselContext.unsubscribe(onChange)
  }, [carouselContext])

  return (
    <>
      <css.MobileImageWrap
        ref={mobileImageRef}
        style={{ aspectRatio: `${width} / ${height}` }}
      >
        <ContentfulImage image={image} />
        {items.map((item, index) => (
          <css.Dot
            key={item.sys.id}
            style={{
              top: `${item.fields.mobileTop}%`,
              left: `${item.fields.mobileLeft}%`,
            }}
            active={index === currentSlide}
            height={
              (mobileImageRef.current?.clientHeight || 0) *
              ((100 - item.fields.mobileTop) / 100)
            }
          />
        ))}
      </css.MobileImageWrap>
      <css.TextContent>
        <Slider
          moveThreshold={0.2}
          className="IICarousel-slider"
          classNameAnimation="IICarousel-tray"
        >
          {items.map((item, index) => (
            <Slide key={index} index={index}>
              <css.SlideWrap>
                <RichTextRenderer document={item.fields.textContent} />
                {item.fields.image ? (
                  <css.Image
                    image={getImageUrl(item.fields.image, { width: 400 })}
                    imageRatio={45 / 80}
                  />
                ) : null}
              </css.SlideWrap>
            </Slide>
          ))}
        </Slider>
        <ButtonBack className="IICarousel-button IICarousel-button--prev">
          <ChevronLeft color="var(--color-text)" height={12} width={7} />
        </ButtonBack>
        <ButtonNext className="IICarousel-button IICarousel-button--next">
          <ChevronRight color="var(--color-text)" height={12} width={7} />
        </ButtonNext>
      </css.TextContent>
    </>
  )
}

export default InteractiveImageSection
